<template>
  <div class="container">
    <router-link to="/feedback">
      <span>快速响应<img src="../assets/img/icon-feedback.png" /></span>
      <h1><img src="../assets/img/icon-feedback.png" alt="意见反馈" />意见反馈</h1>
    </router-link>

    <a :href="'tel:' + item.description" v-for="(item,index) in yjfk" :key="index">
      <span>一键拨号<img src="../assets/img/icon-phone.png" /></span>
      <h1><img :src="item.icon" :alt="item.name" />{{item.name}}</h1>
    </a>

  </div>
</template>
<script>
  import { Toast } from 'vant'
  export default {
    name: 'Service',
    data () {
      return{
        yjfk: [],
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    mounted(){
      console.log('客服咨询')
      this.getTel()
    },
    methods: {
      getTel() {
        var me = this;
        this.$get({
          url: 'baseMenu/getAppMenu',
          params: {
            code: 'cus'
          }
        }).then(rsp => {
          console.log(rsp)
          this.yjfk = rsp;
          let index = 0;
          this.yjfk.map((item,idx) => {
            if(item.code == 'yjfk'){
              index = idx;
            }
          });
          this.yjfk.splice(index,1);
        }).catch( error => {
            Toast(error.message)
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    background:  #F7F7F7;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    a{
      background: #fff;
      border-radius: 3px;
      display: inline-block;
      margin-top: 15px;
      text-align: left;
      line-height: 30px;
      padding: 10px;
      box-sizing: border-box;
      color: #222;
      white-space: nowrap;
      width: 100%;
      h1{
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
        margin-right: 10px;
      }
      span{
        float: right;
        font-size: 12px;
        color: #666;
        img{
          width: 14px;
          height: 14px;
          margin: -2px 0 0 5px;
        }
      }
    }
  }
</style>
